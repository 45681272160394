import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  useBreakpointValue,
  SimpleGrid,
} from '@chakra-ui/react';

/*import { 
  FaHome 
} from "react-icons/fa";
/*
import {
  AiFillHome
} from 'react-icons/ai';
*/
import {
  //SunIcon,
  CheckCircleIcon,
  SettingsIcon,
  StarIcon,
} from '@chakra-ui/icons';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="blue" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

//interface BlogAuthorProps {
//  date: Date;
//  name: string;
//}

{/*export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};
*/}

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src={'../../assets/images/profile/Dan-Profile.png'}
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};


const ArticleList = () => {
  return (
<>
    <Container maxW={'7xl'} p="12">
      <Heading as="h1"
      
      textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
      fontFamily={'Daniel'}
      fontSize={'5xl'}
      fontWeight={'bold'}
      color={useColorModeValue('gray.900', 'white')}
      >Albinson's Blog</Heading>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={
                  'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                }
                alt="Albinson Blog"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(orange.600 1px, transparent 1px)',
                'radial(orange.300 1px, transparent 1px)'
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <BlogTags tags={['Welcome', 'New Website', '3 Core Services']} />
          <Heading marginTop="2">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              Welcome to Albinson's Blog!
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            marginBottom="5"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
              Thanks for visiting! Our blog will be featuring articles relating to our three areas of services including:
            </Text> 
            <HStack spacing='20px' verticalAlign='bottom'>
                <Box w='auto'><CheckCircleIcon /> Real Estate </Box>
                <Box w='auto'>|</Box> 
                <Box w='auto'><SettingsIcon /> Marketing </Box>
                <Box w='auto'>|</Box>
                <Box w='auto'><StarIcon /> Technology </Box>
            </HStack>
           {/* <FaHome />*/}
            {/*<HStack spacing='15px'>
              <Box w='135px' h='30px'><FaHome /> Real Estate</Box>
              <Box w='105px' h='30px'>
                <SettingsIcon /> Technology 
              </Box>
              <Box w='105px' h='30px'>
                <StarIcon /> Marketing
              </Box>
              </HStack>*/}
            
            <Text
                as="p"
                marginTop="4"
                color={useColorModeValue('gray.700', 'gray.200')}
                fontSize="lg"
                display={'inline-block'}>
                We're excited to share our new business name, Albinson, LLC. 
                In business since 2009, we've recently rolled out our new name to make us easier to find. 
                Our Minnesota Real Estate services are offered through Fish MLS Realty brokerage #40344433.
                We also serve Western Wisconsin.
            </Text>  
        <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            We've created our all new website utilizing ReactJS as a showcase of new technology. React was 
            created for the development of Facebook. ReactJS web apps are great for SEO, security, responsiveness, are mobile friendly and fast.
            ReactJS might be a good choice for your new or updated website if most of your content is static. Give us a call or submit a form to get more information on our Real Estate, Marketing or Tech services.
        </Text>
        <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            We hope you enjoy our new site and look forward to serving you under our updated name, Albinson.
            <br />

          </Text>
          <BlogAuthor name="Dan Albinson" date={new Date('2022-04-01T19:01:27Z')} />
        </Box>
      </Box>
      <Heading as="h2" marginTop="5">
        Latest articles
      </Heading>
      <Divider marginTop="5" />


      <SimpleGrid columns={[1, null, 2]} spacing={10}>


              <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: '100%', sm: '100%', md: '100%', lg: '100%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  transform="scale(1.0)"
                  src={
                    './assets/images/realestate/Marketplace-feb-2023.png'
                  }
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Link>
            </Box>
            <BlogTags tags={['Real Estate Market', 'Economic Conditions', 'Housing Inventory']} marginTop="3" />
            <Heading fontSize="xl" marginTop="2">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                MN & WI Real Estate Market - February 2023
              </Link>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              We're in the middle of a market shift towards a more balanced housing market.
              As interest rates rise, some buyer's are getting priced out of the marketplace.
              We've seen the rates go up by 3% in the last year or so.
              This has resulted in the housing inventory to increase and away from historic lows.
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              However, if a home is in great condition and in a sought after community, we're still seeing multiple offer scenarios.
              In fact, appraisal gap coverage, refusal of inspections, and even Sales Price Escalation Addendums are still prevalent.
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              We are carefully watching the marketplace. The broader economy is sending mixed signals, as certain industry sectors are reducing their labor force by the thousands indicating a recession is looming.
              This adds a destabilizing force as higher unemployment rates send ripples through the marketplace.
              Additionally, our National debt is hitting 31 Trillion and the debt ceiling has been hit adding further uncertainty as Congress trys to workout a budget.
            </Text>
            <Text as="p" fontSize="md" marginTop="2">            
              If you're thinking of selling, it's still a decent time to list. Time is of the escence though, so contact us today about getting your home on the market.
            </Text>
            <Text as="p" fontSize="md" marginTop="2">      
              The tricky role in Real Estate is still finding and winning the buyer's side of the transaction.
              We setup searches to find homes that meet your criteria and automatically send them to you through email.
              Once a property of interest is found, 
              we can help you analyze the current property value and put together a compelling Purchase Agreement.     
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
                We would love to help you!
            </Text>
            <BlogAuthor
              name="Dan Albinson, Albinson, LLC"
              date={new Date('2023-02-01T19:01:00')}
            />
          </Box>
        </WrapItem>
      </Wrap>
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: '100%', sm: '100%', md: '100%', lg: '100%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  transform="scale(1.0)"
                  src={
                    './assets/images/marketing/MarketingSuperHero.png'
                  }
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Link>
            </Box>
            <BlogTags tags={['Marketing Strategy', 'Marketing Partner']} marginTop="3" />
            <Heading fontSize="xl" marginTop="2">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                Marketing Partner
              </Link>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              Let us help you get your business in front of your target audience!
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              In order to be the most effective partner to your business, we seek to truly understand your business goals before devising a marketing strategy.
              We need to know what market you serve, what products or services you offer, and what makes you stand out from your competition.
              What makes you and your business deserve to get the business? Where are your opportunities for growth?
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              Depending upon what products and services you offer, location may be a very important part or your marketing plan.
              Brick and mortar stores need to increase their local community visability, as eCommerce sales have dramatically increased competition for all businesses. 
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              SEO strategies for online searches may require a business website to have important location and other key words in their meta data.
              Blog posts with social media syndication to business channels can provide a big boost to market presence and SEO rankings.
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              Meeting with us, in person or over Zoom, we can get a better idea of how to create the best way to enhance your promotional efforts. 
            </Text>
            <Text as="p" fontSize="md" marginTop="2">
              Let's talk about your marketing goals today and see how we might help you meet them tomorrow!
            </Text>
            <BlogAuthor
              name="Dan Albinson, Albinson, LLC"
              date={new Date('2023-02-02T19:01:27Z')}
            />
          </Box>
        </WrapItem>
      </Wrap>


</SimpleGrid>


     {/*
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2">What we write about</Heading>
        <Text as="p" fontSize="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
          pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
          imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
          sapien. Suspendisse placerat vulputate posuere. Curabitur neque
          tortor, mattis nec lacus non, placerat congue elit.
        </Text>
        <Text as="p" fontSize="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
          pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
          imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
          sapien. Suspendisse placerat vulputate posuere. Curabitur neque
          tortor, mattis nec lacus non, placerat congue elit.
        </Text>
        <Text as="p" fontSize="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
          pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
          imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
          sapien. Suspendisse placerat vulputate posuere. Curabitur neque
          tortor, mattis nec lacus non, placerat congue elit.
        </Text>
     </VStack>*/}
    </Container>
</>
  );
};

export default ArticleList;