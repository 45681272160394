import React from 'react';
//import Header from './sections/Header';
//import Footer from './sections/Footer';
//import { AspectRatio } from "@chakra-ui/layout";
import SlimeSaleComp from './components/SlimeSale/SlimeSaleComp';

function SlimeSale() {
    return (
        <>
       {/* <Header /> */}
        <SlimeSaleComp />
        {/*<div>
            <h1>This is the 2024 Slime Sale Page</h1>
        </div>*/}
       {/* <Footer /> */}
        </>
    );
}
export default SlimeSale;