import React from 'react';
import CitySpringLakeParkStats from './components/RealEstate/CitySpringLakeParkStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CitySpringLakePark() {
  return (
    <>
      <Header />
      <CitySpringLakeParkStats />
      <Footer />
    </>
    );
  }
export default CitySpringLakePark;