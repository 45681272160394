import { Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

export default function Success() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Thanks for visiting the all new Albinson.com!
      </Heading>
      <Text color={'gray.500'} size="lg">
      We serve individuals and organizations with Real Estate, Technology and Marketing Services. Contact us toady!
      </Text>
    </Box>
  );
}