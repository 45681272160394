import React from 'react';
import { 
  ChakraProvider, 
  //Stack, 
  //Image, 
  theme, 
  Container, 
  //Show, 
  //Flex 
} from "@chakra-ui/react";
//Flex, Divider, Text,
import CTAwVideo from './components/CTAwVideo';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Headline1 from "./components/Headline1";
//import ProductDetail from "./components/ProductDetail";
//import ProductDetail2 from "./components/ProductDetail2";
import CardProfile from "./components/CardProfile";
//import MarketingComponent from "./components/MarketingComponent";
//import ContactUsComponent from "./components/ContactUsComponent";
//import ThreeColGrid from './components/ThreeColGrid';
import CaptionCarousel from "./components/CaptionCarousel";
//import Footer from './sections/Footer';
import TestimonialBubbles from './components/TestimonialBubbles';
//import LargeQuoteNoPhoto from "./components/LargeQuoteNoPhoto";
//import ServSliderPCTA from './components/ServSliderPCTA';
//import PointsCTA from "./components/PointsCTA";
//import HomePageTextLeftCol from "./components/HomePageTextLeftCol";
//import HomePageTextRightCol from "./components/HomePageTextRightCol";
//import Pcta from './components/Pcta';
//import BlogPostComponent from './components/BlogPostComponent';


function Home() {
    return (
  <>
    {/*  maxW="1200" */}
      <ChakraProvider theme={theme}>
          <Container maxW="100%">
            <Header />
             <CaptionCarousel />
             <TestimonialBubbles />
             <CTAwVideo />
             <Headline1 />
             <CardProfile />
             {/*<ContactUsComponent />*/}
            <Footer />
          </Container>
        </ChakraProvider>
    </>
  );
}
export default Home;