import {
    Heading,
    Avatar,
    Box,
    Center,
    Image,
    Flex,
    Link,
    Text,
    Stack,
    Button,
    useColorModeValue,
  } from '@chakra-ui/react';
  
  export default function SocialProfileWithImage() {
    return (
      <Center py={6}>
        <Box
          maxW={'270px'}
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}>
          <Image
            h={'120px'}
            w={'full'}
            src={
              //'https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
              '../assets/images/profile/ProfileCardBackground2022.png'
            }
            objectFit={'cover'}
          />
          <Flex justify={'center'} mt={-12}>
            <Avatar
              size={'xl'}
              src={
                //'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ'
                '../assets/images/profile/DanAlbinsonProfile2022_web600.png'
              }
              alt={'Author'}
              css={{
                border: '2px solid white',
              }}
            />
          </Flex>
  
          <Box p={6}>
            <Stack spacing={0} align={'center'} mb={5}>
              <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                Dan Albinson
              </Heading>
              <Text color={'gray.500'} fontSize={'xs'}>Realtor | Developer | Marketing Pro </Text>
            </Stack>
            <Text color={'gray.500'} textAlign={'center'} fontWeight={'semibold'} fontSize={'xs'}>YEARS OF EXPERIENCE</Text>

            <Stack direction={'row'} justify={'center'} spacing={6}>
              <Stack spacing={0} align={'center'}>
                <Text fontWeight={600}>16</Text>
                <Text fontSize={'sm'} color={'gray.500'}>
                  Real Estate
                </Text>
              </Stack>
              <Stack spacing={0} align={'center'}>
                <Text fontWeight={600}>21</Text>
                <Text fontSize={'sm'} color={'gray.500'}>
                  Tech
                </Text>
              </Stack>
              <Stack spacing={0} align={'center'}>
                <Text fontWeight={600}>16</Text>
                <Text fontSize={'sm'} color={'gray.500'}>
                  Marketing
                </Text>
              </Stack>
            </Stack>
            <Link href={'./ContactUs'} >
              <Button
                w={'full'}
                mt={8}
                bg={useColorModeValue('#151f21', 'gray.900')}
                color={'white'}
                rounded={'md'}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}>
                Contact Albinson
              </Button>
            </Link>
          </Box>
        </Box>
      </Center>
    );
  }