import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
    IoAnalyticsSharp,
   // IoLogoBitcoin,
    //IoSearchSharp,
    IoEarthSharp,
    IoCameraSharp,
  } from 'react-icons/io5';
  import { ReactElement } from 'react';
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function SplitWithImage() {
    return (
      <Container maxW={'5xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.950'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.200', 'blue.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
               Digital Footprint | Enhance Your Brand
            </Text>
            <Heading>What's your business goal?</Heading>
            <Text color={'gray.50', 'gray.500'} fontSize={'lg'}>
              There has never been a more important time to have a great online storefront or website promoting your brand.
              Albinson has helped many clients create successful eCommerce and promotional websites that are intuitive and deliver a great user experience.
              </Text>
              <Text color={'gray.100', 'gray.500'} fontSize={'lg'}>
              Utilizing WordPress, and Shopify platforms, we've helped setup custom online stores.
              We can handle everything from the site setup to product photography, catalog creation,
              real-time inventory, invoicing reports, packing slips, connecting payment gateways and email promotion campaigns. 
            </Text>
            <Text color={'gray.100', 'gray.500'} fontSize={'lg'}>
              Albinson has created many informational/promotional websites to help our clients put their best digital footprint out on the internet.
              One of the first things potential customers do is look your business up online. 
              How's your digital image? Are you instilling confidence in your brand and services by what people find?
              How can you improve upon what your present site is communicating?
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                />
              }>
              <Feature
              IoAnalyticsSharp
                icon={
                  <Icon as={IoEarthSharp} color={'blue.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('blue.100', 'blue.900')}
                text={'Online Store Setup | Your Preferred Platform'}
              />
              <Feature
                icon={<Icon as={IoCameraSharp} color={'blue.500'} w={5} h={5} />}
                iconBg={useColorModeValue('blue.100', 'blue.900')}
                text={'Product Catalogs | Inventory | Invoicing | Packing Slips'}
              />
              <Feature
                icon={
                  <Icon as={IoAnalyticsSharp} color={'blue.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('blue.100', 'blue.900')}
                text={'Payment Gateway & Secure Sales'}
              />
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                //'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
               './assets/images/marketing/MarketingBusinessGoals234.png'
              }
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
      </Container>
    );
  }