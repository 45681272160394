import React from "react";
import {
 // Flex,
  Text,
  Stack,
  Heading,
  useBreakpointValue,
  SimpleGrid,
  Container,
} from '@chakra-ui/react';

//import ReactDOM from "react-dom";
//class App extends React.Component {
 // render() {

 function CityHopkinsStats () {
    return (
<>
<Container 
  maxW={'1200px'}>
          <Heading 
            fontSize={{ base: '3xl', md: '4xl', lg: '4xl' }} 
            textAlign={'center'}
            py={'10'}
            >
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: useBreakpointValue({ base: '20%', md: '30%' }),
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'blue.200',
                zIndex: -1,
              }}>
                Hopkins, Minnesota
            </Text>
            <br />
            <Text color={'blue.400'} fontSize={{ base: '2xl', md: 'xl', lg: '2xl' }} color={'blue.300'} as={'span'}>
              Community Housing Stats
            </Text>
          </Heading>

      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, sm: 1, }} spacing={2}>
      
      {/* ROW 1 */}
        <Stack 
          direction={{ base: 'row', md: 'row', }} 
          spacing={4}
          margin={'auto'}
        >
          {/* --- 1. AVERAGE SALE PRICE --- */}
          <iframe 
              src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcr-5Rf?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
            />
        </Stack>
        <Stack 
          direction={{ base: 'row', md: 'row', }} 
          spacing={4}
          margin={'auto'}
        >
          {/* --- 2. MEDIAN SALE PRICE --- */}
            <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcD-kF5?w=400&h=300"}
            width={"450px"}
            height={"350px"}
            py={"4px"}
            margin={"auto"}
            />
        </Stack>
      {/* ROW 2 */}    
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 3. SHOWINGS PER LISTING - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAca-7ie?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 4. AVERAGE SHOWINGS TO PENDING --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAci-Q56?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 3 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 5. MEDIAN SHOWINGS PER LISTING - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcJ-G3y?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 6. AVERAGE % OF LIST PRICE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAc5-Yvh?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 4 */}
           <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
              {/* --- 7. AVERAGE PRICE PER SQ FT - CURRENT MONTH --- */}
                <iframe 
                    src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcU-bqD?w=400&h=300'}
                    width={"450px"}
                    height={"350px"}
                    py={"4px"}
                    margin={"auto"}
                  />
            </Stack>
            <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
              {/* --- 8. MEDIAN PRICE PER SQ FT  --- */}
              <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcW-Pdb?w=400&h=300"}
                width={"450px"}
                height={"350px"}
                py={"4px"}
                margin={"auto"}
                />
          </Stack>          
      {/* ROW 5 */}
            <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
             {/* --- 9. MONTHS SUPPLY OF HOMES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAc6-Tgk?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 10. MEDIAN DAYS ON MARKET  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAco-fJm?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>              
      {/* ROW 6 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 11. AVERAGE DAYS ON MARKET - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAck-p30?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 12. MONTHS SUPPLY OF HOMES FOR SALE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcc-8xm?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>           
      {/* ROW 7 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 13. NEW LISTINGS TWIN CITIES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QAcN-xf3?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 14. NEW LISTINGS SPECIFIC TOWN FOR SALE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QANd-TkE?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>       
      {/* ROW 8 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 15. SPECIFIC TOWN HOMES FOR SALE - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QANf-yZh?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 16. SPECIFIC TOWN PENDING SALES  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QANP-DFq?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>    
      {/* ROW 9 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 17. SPECIFIC TOWN CLOSED SALES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/QANF-cYY?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>

      </SimpleGrid>
    </Container> 
    </>
  );
}
export default CityHopkinsStats;
