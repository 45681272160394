import React from 'react';
import CaptionCarouselTK from './components/Technology/CaptionCarouselTK';
import Header from './sections/Header';
import Footer from './sections/Footer';

function Technology() {
    return (
        <>
        <Header />
        <CaptionCarouselTK />
        <div>
            <h1>THIS is the TECH page</h1>
        </div>
        <Footer />
        </>
    );
}
export default Technology;
