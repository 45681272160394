import React from 'react';
import {
    Text,
    Container,
} from '@chakra-ui/react';
import ContactUsComponent from './components/ContactUsComponent';
import Header from './sections/Header';
import Footer from './sections/Footer';

function ContactUs() {
    return (
        <>
            <Header />
            <Container mb={'5'}>
                <Text 
                    color="0D2342"
                    fontSize={'1.7em'}
                    textAlign={'center'}
                >
                    We would love to hear from you!</Text>
                <Text
                    color="0D2342"
                    fontSize={'1.2em'}
                    textAlign={'center'}
                >
                Please complete the form below and we'll get back to you shortly.</Text>
            </Container>
            <ContactUsComponent />
            <Footer />
        </>     
    );
}

export default ContactUs;