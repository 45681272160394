import {
    Container,
    SimpleGrid,
    Box,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
   // IoAnalyticsSharp,
   // IoLogoBitcoin,
    //IoSearchSharp,
   // IoEarthSharp,
   // IoCameraSharp,
    IoImagesOutline,
    IoBrowsersOutline,
  } from 'react-icons/io5';
  import {
    MdDevicesOther,

  } from 'react-icons/md';

  import { ReactElement } from 'react';
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function SplitWithImage() {
    return (
      <Container maxW={'5xl'} py={12}>
        <Box marginBottom={'25'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* IMAGE LEFT / 1ST IN RESPONSIVE... */}
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                '/assets/images/realestate/Sold78.png'
              }
              objectFit={'cover'}
            />
          </Flex>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.950'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('green.200', 'green.400')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Selling Your Home
            </Text>
            <Heading>We Sell Well! </Heading>
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
              We want to help you position your home properly to maximize your sale proceeds.
              We take this responsibility very seriously and do our market analysis with care and attention to detail.

              </Text>
              <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                Leveraging a B.A. in Economics, Albinson is continually following the ever changing market conditions.
                We base our analysis on recent activity in a home's immediate neighborhood.

             </Text> 
             <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >  
               We deliver a professional, systematic approach to maximize your home sale.  
            </Text>  
 
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.500')}
                />
              }>
              <Feature
                MdDevicesOther
                icon={
                  <Icon as={MdDevicesOther} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.200', 'green.100')}
                text={'Professional, Systematic & Detailed Approach'}
              />
              <Feature
                IoImagesOutline
                icon={<Icon as={IoImagesOutline} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.200', 'green.100')}
                text={'Micro-Market Analysis & Research of Neighborhood'}
              />
              <Feature
                IoBrowsersOutline
                icon={
                  <Icon as={IoBrowsersOutline} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.200', 'green.100')}
                text={'Professional Marketing & Communication'}
              />
            </Stack>
          </Stack>

        </SimpleGrid>
       </Box>
<br />
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* IMAGE LEFT / 1ST IN RESPONSIVE... */}

          <Stack spacing={3}>
            {/*<Text
              textTransform={'uppercase'}
              color={'blue.950'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.200', 'blue.400')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}
              marginBottom={'5'}>
              Listing Plan Overview
            </Text>*/}
         {/*} </Stack>
          <Stack spacing={2}>*/}
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}
                  paddingTop={'0'}>
                  Listing Process Outline<br />
                <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    We provide our clients with a Listing Outline so they know what to expect during the selling process.
                </Text>
            </Text>
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Documentation<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    Listing Documentation can be completely handled online for customer convenience and timely delivery.
                </Text>
            </Text>   
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                  >
                  Pre-Listing Onsite Property Review<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    Albinson visits your property to review the home's features and conditions.
                We send a punchlist report of items to address for maximum sale return.
                </Text>
            </Text>   
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Staging Strategy<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    After pre-viewing the home, Albinson develops a staging strategy with clients to optimize spaces.
                </Text>
            </Text>
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Professional Marketing<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    As an experienced Photographer, Web Developer and Social Media Marketer, 
                    Albinson has the technical and design skills to create powerful marketing campaigns maximizing your home's market reach.                 </Text>
            </Text>    
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Sales Negotiations<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    Selling Real Estate since 2007, Albinson is an experienced negotiator in home sales in up and down markets. Experience matters.
                 </Text>
            </Text> 
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Net Proceeds<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    We give our clients Net Proceeds Estimates during the sales negotiation process. 
                    We review and compare all offers through a spreadsheet comparison.
                 </Text>
            </Text>   
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Closing Coordination<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                     We connect our clients to their preferred Title Company and help coordinate closing details.
                 </Text>
            </Text>   
            </Stack>
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                '/assets/images/realestate/ListingContractCoverPg.jpg'

              }
              objectFit={'cover'}
            />
          </Flex>

        </SimpleGrid>     


      </Container>
    );
  }