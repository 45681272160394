import React from 'react';
import CityMapleGroveStats from './components/RealEstate/CityMapleGroveStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityMapleGrove() {
  return (
    <>
      <Header />
      <CityMapleGroveStats />
      <Footer />
    </>
    );
  }
export default CityMapleGrove;