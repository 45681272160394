import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import { extendTheme } from "@chakra-ui/react";
import App from "./App";
//import Header from './sections/Header';
//import Footer from './sections/Footer';
import './fonts/daniel.ttf';


const theme = extendTheme({
  colors: {
    brand: {
      100: "#f7fafc",
      // ...
      900: "#1a202c",
    },
  },
})


ReactDOM.render (
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App>

      </App>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);