import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    //IconButton,
    Button,
    VStack,
    //HStack,
    Stack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Checkbox, 
    CheckboxGroup,
    //HStack, 
    //Row,
  } from '@chakra-ui/react';
  import {
    MdPhone,
   // MdEmail,
   // MdLocationOn,
   // MdFacebook,
    MdOutlineEmail,
    MdBusiness,
  } from 'react-icons/md';

  import { 
  //  BsGithub, 
  //  BsDiscord, 
    BsPerson 
  } from 'react-icons/bs';


  
  export default function ContactUsComponent() {
    return (
      <Container bg="#4C7BBE" maxW="full" mt={0} centerContent overflow="hidden">
        {/* bg="#9DC4FB"*/}
        <Flex>
          <Box
            bg="#0D2342"
            color="white"
            borderRadius="lg"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}>

            <Heading textAlign={'center'}>Contact Us</Heading>
              <Text mt={{ sm: 3, md: 3, lg: 5 }} textAlign='center' color='white.700'>
                Please Complete The Form Below
              </Text>
            <Box p={4}>
              <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                <WrapItem>
                  <Box bg="white" borderRadius="lg">
                    <Box m={8} color="#0B0E3F">
                      <VStack spacing={5}>
                        <FormControl id="yourname">
                          <FormLabel>First & Last Name</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsPerson color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="email">
                          <FormLabel>Email</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdOutlineEmail color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="phone">
                          <FormLabel>Phone</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdPhone color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="businessname">
                          <FormLabel>Business Name</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdBusiness color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="interestarea">
                          <Text mt={{ sm: 1, md: 1, lg: 1 }} fontSize='1.4em'  mb={'4'} fontWeight='bold' py={'1'}  textAlign='left'  color="gray.700">
                            Area of Interest:
                          </Text>
                          <CheckboxGroup colorScheme='green' py={'10'}>
                            <Stack spacing={[1, 5]} direction={['column', 'row']}>
                              <Checkbox value='realestate'>Real Estate</Checkbox>
                              <Checkbox value='technology'>Technology</Checkbox>
                              <Checkbox value='marketing'>Marketing</Checkbox>
                            </Stack>
                          </CheckboxGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Message</FormLabel>
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: 'gray.300',
                            }}
                            placeholder="message"
                          />
                        </FormControl>

                        <FormControl id="send" float="right">
                          <Button
                            variant="solid"
                            bg="#0D74FF"
                            color="white"
                            _hover={{}}>
                            Send Message
                          </Button>
                        </FormControl>
                        
                      </VStack>
                    </Box>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>
        </Flex>
        <Flex>
          <Box
            mb={'10'}
          >
            <Text 
              textAlign={'center'}
              color={'#FFF'}
              fontWeight={'bold'}
              fontSize={'1.2em'}
              >
                <Text 
                  as={'span'}
                  fontFamily={'Daniel'}
                  fontSize={'2em'}
                  >
                    Albinson
                </Text><br />

            Serving Minnesota & Western Wisconsin<br />
            Albinson.com | info@Albinson.com <br />
            (612) 270 - 0492
            </Text>
          </Box>
        </Flex>
      </Container>
    );
  }