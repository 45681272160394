import React from "react";
import {
 // Flex,
  Text,
  Stack,
  Heading,
  useBreakpointValue,
  SimpleGrid,
  Container,
} from '@chakra-ui/react';

//import ReactDOM from "react-dom";
//class App extends React.Component {
 // render() {

 function CityPriorLakeStats () {
    return (
<>
<Container 
  maxW={'1200px'}>
          <Heading 
            fontSize={{ base: '3xl', md: '4xl', lg: '4xl' }} 
            textAlign={'center'}
            py={'10'}
            >
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: useBreakpointValue({ base: '20%', md: '30%' }),
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'blue.200',
                zIndex: -1,
              }}>
              Prior Lake, Minnesota
            </Text>
            <br />
            <Text color={'blue.400'} fontSize={{ base: '2xl', md: 'xl', lg: '2xl' }} color={'blue.300'} as={'span'}>
              Community Housing Stats
            </Text>
          </Heading>

      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, sm: 1, }} spacing={5}>
      
      {/* ROW 1 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 1. AVERAGE SALE PRICE --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1ukk-aIw?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 2. MEDIAN SALE PRICE --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1uke-EAc?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 2 */}    
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 3. SHOWINGS PER LISTING - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9r-dN?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 4. AVERAGE SHOWINGS TO PENDING --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9s-wB2?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 3 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 5. MEDIAN SHOWINGS PER LISTING - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9X-4LK?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 6. AVERAGE % OF LIST PRICE  --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9S-Oyj?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 4 */}
           <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
              {/* --- 7. AVERAGE PRICE PER SQ FT - CURRENT MONTH --- */}
                <iframe 
                    src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9C-5O?w=400&h=300'}
                    width={"450px"}
                    height={"350px"}
                    py={"4px"}
                    margin={"auto"}
                  />
            </Stack>
            <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
              {/* --- 8. MEDIAN PRICE PER SQ FT  --- */}
              <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1ukG-9Cr?w=400&h=300'}
                width={"450px"}
                height={"350px"}
                py={"4px"}
                margin={"auto"}
                />
          </Stack>          
      {/* ROW 5 */}
            <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
             {/* --- 9. MONTHS SUPPLY OF HOMES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9J-TTY?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 10. MEDIAN DAYS ON MARKET  --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9L-lOc?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>              
      {/* ROW 6 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 11. AVERAGE DAYS ON MARKET - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9i-k8i?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 12. AVG LIST PRICE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9l-nqN?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>           
      {/* ROW 7 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 13. NEW LISTINGS  - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u95-KH2?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 14. HOMES FOR SALE SPECIFIC TOWN FOR SALE  --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u96-XA5?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>       
      {/* ROW 8 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 15. MEDIAN LIST PRICE - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u97-ikk?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 16. SPECIFIC TOWN PENDING SALES  --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9p-0zk?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>    
      {/* ROW 9 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 17. SPECIFIC TOWN CLOSED SALES - CURRENT MONTH --- */}
             <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u9x-Pi4?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
        >
             {/* --- 18. SPECIFIC TOWN NEW LISTINGS  --- */}
             <iframe src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/1u95-KH2?w=400&h=300'}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
        </Stack>
      </SimpleGrid>
    </Container> 
    </>
  );
}
export default CityPriorLakeStats;