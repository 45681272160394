import React from "react";
import {
 // Flex,
  Text,
  Stack,
  Heading,
  useBreakpointValue,
  SimpleGrid,
  Container,
} from '@chakra-ui/react';

//import ReactDOM from "react-dom";
//class App extends React.Component {
 // render() {

 function CityChaskaStats () {
    return (
<>
<Container 
  maxW={'1200px'}>
          <Heading 
            fontSize={{ base: '3xl', md: '4xl', lg: '4xl' }} 
            textAlign={'center'}
            py={'10'}
            >
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: useBreakpointValue({ base: '20%', md: '30%' }),
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'blue.200',
                zIndex: -1,
              }}>
              Chaska, Minnesota
            </Text>
            <br />
            <Text color={'blue.400'} fontSize={{ base: '2xl', md: 'xl', lg: '2xl' }} color={'blue.300'} as={'span'}>
              Community Housing Stats
            </Text>
          </Heading>

      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, sm: 1, }} spacing={5}>
      
      {/* ROW 1 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 1. AVERAGE SALE PRICE --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbd-JYn?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 2. MEDIAN SALE PRICE --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbh-ITh?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 2 */}    
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 3. SHOWINGS PER LISTING - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbA-4Eb?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 4. AVERAGE SHOWINGS TO PENDING --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbL-Ct9?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 3 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 5. MEDIAN SHOWINGS PER LISTING - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbs-tVh?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 6. AVERAGE % OF LIST PRICE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbx-WOC?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>
      {/* ROW 4 */}
           <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
              {/* --- 7. AVERAGE PRICE PER SQ FT - CURRENT MONTH --- */}
                <iframe 
                    src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swb5-N9J?w=400&h=300'}
                    width={"450px"}
                    height={"350px"}
                    py={"4px"}
                    margin={"auto"}
                  />
            </Stack>
            <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
              {/* --- 8. MEDIAN PRICE PER SQ FT  --- */}
              <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbV-bUk?w=400&h=300"}
                width={"450px"}
                height={"350px"}
                py={"4px"}
                margin={"auto"}
                />
          </Stack>          
      {/* ROW 5 */}
            <Stack 
              direction={{ base: 'row', md: 'row', }} 
              spacing={4}
              margin={'auto'}
              >
             {/* --- 9. MONTHS SUPPLY OF HOMES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swbv-Je9?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 10. MEDIAN DAYS ON MARKET  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swl2-Rjl?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>              
      {/* ROW 6 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 11. AVERAGE DAYS ON MARKET - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swlQ-exn?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 12. MONTHS SUPPLY OF HOMES FOR SALE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swl7-wsw?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>           
      {/* ROW 7 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 13. NEW LISTINGS TWIN CITIES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swls-hfx?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 14. NEW LISTINGS SPECIFIC TOWN FOR SALE  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swlA-gJ4?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>       
      {/* ROW 8 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 15. SPECIFIC TOWN HOMES FOR SALE - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/s8Kr-yPU?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 16. SPECIFIC TOWN PENDING SALES  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/s8Kr-yPU?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
          </Stack>    
      {/* ROW 9 */}
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 17. SPECIFIC TOWN CLOSED SALES - CURRENT MONTH --- */}
              <iframe 
                  src={'https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swlD-8b3?w=400&h=300'}
                  width={"450px"}
                  height={"350px"}
                  py={"4px"}
                  margin={"auto"}
                />
          </Stack>
          <Stack 
            direction={{ base: 'row', md: 'row', }} 
            spacing={4}
            margin={'auto'}
            >
             {/* --- 18. SPECIFIC TOWN PENDING SALES  --- */}
             <iframe src={"https://northstarmls.stats.10kresearch.com/infoserv/s-v1/swlK-REM?w=400&h=300"}
              width={"450px"}
              height={"350px"}
              py={"4px"}
              margin={"auto"}
              />
            </Stack> 
      </SimpleGrid>
    </Container> 
    </>
  );
}
export default CityChaskaStats;
