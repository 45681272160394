import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Link,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';
//import { Link } from 'react-router-dom';

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

function TestimonialText({ children }: { children: ReactNode; }) {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
}

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string;
  name: string;
  title: string;
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW={'7xl'} py={8} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>Services We Offer
          </Heading>
          <Text>Real Estate, Technology and Marketing Services</Text>
          <Text>Click the Icons below to learn more.</Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}>
          <Testimonial>
            
            <TestimonialContent>
              <TestimonialHeading>Real Estate</TestimonialHeading>
              <TestimonialText>
              Albinson serves Minnesota &amp; Western Wisconsin with expert Real Estate listing 
              &amp; buying services. We have extensive experience with new home construction &amp; remodeling too. 
              Producing great results since 2007, check out our Real Estate Services:<br /> <Link href='realestate'><strong>Albinson Real Estate</strong></Link>!
              </TestimonialText>
            </TestimonialContent>
            <Link href='realestate'>
              <TestimonialAvatar
                src={
                  '../assets/images/icons/Albinson_RealEstateCircle.png'

                  //'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
                }
                name={'Real Estate Services'}
                title={'Minnesota & Western Wisconsin '}
                minW={'100px'}
                href={'pages/realestate'}
              />
            </Link>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Technology</TestimonialHeading>
              <TestimonialText>
                Albinson delivers quality technology solutions including: responsive website development, wire frames, rapid prototyping, SEO, 
                &amp; UX/UI for a wide range of industries and clientele. Established in 2009, check out our Technology Services:<br /> <Link href='technology'><strong>Albinson Technology</strong></Link>!
              </TestimonialText>
            </TestimonialContent>
            <Link href='technology'>
              <TestimonialAvatar
                src={
                  '../assets/images/icons/Albinson_TechnologyCircle.png'
                  //'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
                }
                name={'Technology Services'}
                title={'Web | eCommerce |  Blogs | SEO | UX/UI'}
              />
            </Link>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Marketing</TestimonialHeading>
              <TestimonialText>
              Albinson offers custom marketing services including: branding, design, email marketing, blogs &amp;
              social media syndication services for a diverse group of clients and businesses. 
              Established in 2009, check out our Marketing Services:<br /> <Link href='marketing'><strong>Albinson Marketing</strong></Link>!
              </TestimonialText>
            </TestimonialContent>
            <Link href='marketing'>
              <TestimonialAvatar
                src={
                  '../assets/images/icons/Albinson_MarketingCircle.png'
                  //'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
                }
                name={'Marketing Services'}
                title={'Design/Branding | Media | Photos | Email'}
              />
            </Link>
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}