import {
    Container,
    Stack,
   // HStack,
    Flex,
    Box,
    Heading,
    Text,
   // Button,
    Image,
    Icon,
    //IconButton,
    createIcon,
    IconProps,
    useColorModeValue,
    Link,
    SimpleGrid,
    Grid,
  } from '@chakra-ui/react';
  import { CheckCircleIcon } from '@chakra-ui/icons';
//import { BluetoothAudioFontIcon } from '@react-md/material-icons';
  
  export default function CallToActionWithVideo() {
    return (
      <Container maxW={'7xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 8 }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '30%',
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'green.400',
                  zIndex: -1,
                }}>
                City Market Data
              </Text>
              <br />
              <Text as={'span'} color={'green.400'}>
                Activity by City
              </Text>
            </Heading>
            <Text color={'gray.500'}>
            Click on the market buttons below to see dynamically updated statistics by city/township. 
            Albinson utilizes recent comparibles and micro-market data to expertly analyze property values.
            </Text>
            <Text color={'gray.500'}>
            Chart data is updated by Northstar MLS. 
            Please revisit the site frequently for up to date market information!
            Let us know if you'd like to see an additional community added to the list!
            </Text>
            <Heading as="h3" size="md" mt={0} mb={4}>
            Charts include information such as:
            </Heading>
           {/* <Text fontSize={'lg'} fontWeight={'600'} martinTop={'0'} marginBottom={'0'} paddingBottom={'0'}>
            </Text> */}
            <Box marginLeft='10% !important' marginTop='20px'>
                <ul paddingTop={'1'} marginTop={'1'}>
                    <li>
                        Average &amp; Median Sales Price
                    </li>
                    <li>
                        Showings per Listing - Current Month 
                    </li>

                    <li>
                        Average &amp; Median Showings to Pending
                    </li>
                    <li>
                        Average &amp; Median % of Original List to Sales Price
                    </li>
                    <li>
                        Average &amp; Median Price per Sq. Ft.
                    </li>
                    <li>
                        Months Supply of Homes
                    </li> 
                    <li>
                        Average &amp; Median Days on Market
                    </li>
                    <li>
                        Number of Homes on the Market (Current Month)
                    </li>
                    <li>
                        New Listings by Month 
                    </li>
                    <li>
                        Homes for Sale by Month 
                    </li>
                    <li>
                        Pending Sales by Month
                    </li>
                    <li>
                        Closed Sales by Month
                    </li>
                </ul> 
            </Box>
        </Stack>





          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Blob
              w={'150%'}
              h={'150%'}
              position={'absolute'}
              top={'-20%'}
              left={0}
              zIndex={-1}
              color={useColorModeValue('green.50', 'green.400')}
            />
            <Box
              position={'relative'}
              height={'300px'}
              rounded={'2xl'}
              boxShadow={'2xl'}
              width={'full'}
              overflow={'hidden'}>
              {/*<IconButton
                aria-label={'Play Button'}
                variant={'ghost'}
                _hover={{ bg: 'transparent' }}
                icon={<PlayIcon w={12} h={12} />}
                size={'lg'}
                color={'white'}
                position={'absolute'}
                left={'50%'}
                top={'50%'}
                transform={'translateX(-50%) translateY(-50%)'}
              />*/}
              <Image
                alt={'Hero Image'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={
                  //'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                  '/assets/images/realestate/MarketDataBySelectCity1000.jpg'
                }
              />
            </Box>
          </Flex>
    </Stack>

   {/* <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 8 }}
          direction={{ base: 'column', md: 'column' }}>

        <HStack
              spacing={{ base: 8, sm: 4 }}
              direction={{ base: 'column', sm: 'row' }}>
              <Link  href='CityAndover'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Andover
                </Button>
            </Link> 
            <Link  href='CityBlaine'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Blaine
                </Button>
            </Link>       
              <Link  href='CityChanhassen'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Chanhassen
                </Button>
            </Link> 

        </HStack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>

<Link  href='CityChaska'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Chaska
                </Button>
            </Link> 


            <Link  href='CityEdenPrairie'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Eden Prairie
                </Button>
            </Link> 
            <Link  href='CityGoldenValley'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Golden Valley
                </Button>
            </Link> 
        </Stack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
            <Link  href='CityEdenPrairie'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Eden Prairie
                </Button>
            </Link> 
            <Link  href='CityGoldenValley'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Golden Valley
                </Button>
            </Link> 
        </Stack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
            <Link  href='CityEdenPrairie'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Eden Prairie
                </Button>
            </Link> 
            <Link  href='CityGoldenValley'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Golden Valley
                </Button>
            </Link> 
        </Stack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
            <Link  href='CityEdenPrairie'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Eden Prairie
                </Button>
            </Link> 
            <Link  href='CityGoldenValley'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Golden Valley
                </Button>
            </Link> 
        </Stack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
            <Link  href='CityEdenPrairie'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Eden Prairie
                </Button>
            </Link> 
            <Link  href='CityGoldenValley'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Golden Valley
                </Button>
            </Link> 
        </Stack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
            <Link  href='CityEdenPrairie'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Eden Prairie
                </Button>
            </Link> 
            <Link  href='CityGoldenValley'>
                <Button
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'red.400'}
                    _hover={{ bg: 'red.500' }}
                    minWidth={'165'}>
                    Golden Valley
                </Button>
            </Link> 
        </Stack>
            </Stack>*/}
<Grid display={'row'}>
      
      <Heading as="h3" size="md" mt={2} mb={6}>
      <CheckCircleIcon boxSize={'30px'} color={'green.500'} id={'Cities'} /> Click the community button below to view up to date Stats:
      </Heading>      
</Grid>
        <SimpleGrid minChildWidth='165px' spacing='20px' rounded={'10'}>
            <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Andover
                </Box>
            </Link>
            <Link href='CityBlaine'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Blaine
                </Box>
            </Link>
            <Link href='CityChanhassen'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Chanhassen
                </Box>
            </Link>
            <Link href='CityChaska'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Chaska
                </Box>
            </Link>
            <Link href='CityEdenPrairie'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Eden Prairie
                </Box>
            </Link>
            <Link href='CityGoldenValley'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Golden Valley
                </Box>
            </Link>
            <Link href='CityHamLake'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Ham Lake
                </Box>
            </Link>
            <Link href='CityHopkins'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Hopkins
                </Box>
            </Link>
            <Link href='CityMapleGrove'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Maple Grove
                </Box>
            </Link>
            <Link href='CityMinnetonka'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Minnetonka
                </Box>
            </Link>
            <Link href='CityOakGrove'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Oak Grove
                </Box>
            </Link>
            <Link href='CityPlymouth'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Plymouth
                </Box>
            </Link>
            <Link href='CityPriorLake'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Prior Lake
                </Box>
            </Link>            
            <Link href='CityRamsey'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Ramsey
                </Box>
            </Link>
            <Link href='CitySavage'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Savage
                </Box>
            </Link>
            <Link href='CitySpringLakePark'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}
                     border={'solid 5px #FFF'}>
                         Spring Lake Park
                </Box>
            </Link>
         {/*   <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}>
                         Andover
                </Box>
            </Link>
            <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}>
                         Andover
                </Box>
            </Link>
            <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}>
                         Andover
                </Box>
            </Link>
            <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}>
                         Andover
                </Box>
            </Link>
            <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}>
                         Andover
                </Box>
            </Link>
            <Link href='CityAndover'>
                <Box 
                    bg={'#0D2342'} 
                    _hover={{ bg: 'blue.400' }}
                     height='50px' 
                     rounded={'30'} 
                     textAlign={'center'} 
                     color={'white'} 
                     fontWeight={'bold'} 
                     paddingTop={'2'}>
                         Andover
                </Box>
        </Link>*/}
        

        </SimpleGrid>
      </Container>
    );
  }
  
 const PlayIcon = createIcon({
    displayName: 'PlayIcon',
    viewBox: '0 0 58 58',
    d:
      'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
  });
  
  export const Blob = (props: IconProps) => {
    return (
      <Icon
        width={'100%'}
        viewBox="0 0 578 440"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
          fill="currentColor"
        />
      </Icon>
    );
  };