import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import MarketingPhotoComp from './components/Marketing/MarketingPhotoComp';

function MarketingPhoto() {
    return (
        <>
        <Header />
        <MarketingPhotoComp />
        <Footer />
        </>
    );
}

export default MarketingPhoto;