import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import RealEstateCitiesComp from './components/RealEstate/RealEstateCitiesComp';

function RealEstateCities() {
    return(
        <>
            <Header />
            <RealEstateCitiesComp />
            <Footer />
        </>
    );
}
export default RealEstateCities;