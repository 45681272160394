import React from 'react';
import CityGoldenValleyStats from './components/RealEstate/CityGoldenValleyStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityGoldenValley() {
  return (
    <>
      <Header />
      <CityGoldenValleyStats />
      <Footer />
    </>
    );
  }
export default CityGoldenValley;