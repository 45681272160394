import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
   // IoAnalyticsSharp,
   // IoLogoBitcoin,
    //IoSearchSharp,
   // IoEarthSharp,
   // IoCameraSharp,
    IoImagesOutline,
    IoBrowsersOutline,
  } from 'react-icons/io5';
  import {
    MdDevicesOther,

  } from 'react-icons/md';

  import { ReactElement } from 'react';
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function SplitWithImage() {
    return (
      <Container maxW={'5xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* IMAGE LEFT / 1ST IN RESPONSIVE... */}
        <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                './assets/images/technology/Responsive2023.png'
              }
              objectFit={'cover'}
            />
          </Flex>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.950'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.200', 'blue.400')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Responsive Websites
            </Text>
            <Heading>Is your website responsive?</Heading>
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
              How does your website look on a smart phone? Tablet? Laptop? Desktop?
              </Text>
              <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
              Did you know that over 50% of web traffic is from mobile devices? 
              Your business needs to look good to visitors online or in person.
              
              </Text>
              <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                We help customer websites perform well on whatever device people use to access it.
                It's so important to give all customers a good user experience. 
             </Text>   
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >    
                Since you won't know what device clients may use, responsive design is more important than ever.
                As new devices come out, we periodically review client sites to make sure they look great.
            </Text>
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >   
                If you need help getting your site to look right on all devices, give us a call at <Text as="span" fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                fontWeight={'semibold'}
                >
                (612) 270-0492</Text>
                . We can help!
            </Text>

            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.500')}
                />
              }>
              <Feature
                MdDevicesOther
                icon={
                  <Icon as={MdDevicesOther} color={'blue.500'} w={5} h={5} />}
                iconBg={useColorModeValue('blue.200', 'blue.100')}
                text={'Design Websites to Adapt to Most Devices (Viewports)'}
              />
              <Feature
                IoImagesOutline
                icon={<Icon as={IoImagesOutline} color={'blue.500'} w={5} h={5} />}
                iconBg={useColorModeValue('blue.200', 'blue.100')}
                text={'Create & Optimize Visual Assets with Responsive Design'}
              />
              <Feature
                IoBrowsersOutline
                icon={
                  <Icon as={IoBrowsersOutline} color={'blue.500'} w={5} h={5} />}
                iconBg={useColorModeValue('blue.200', 'blue.100')}
                text={'Test Sites Using Browser and OS Evaluation Tool'}
              />
            </Stack>
          </Stack>

        </SimpleGrid>
      </Container>
    );
  }