import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
 // Divider,
  HStack,
  Tag,
  //Wrap,
 // WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  //VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

//import {
//  SunIcon,
//  SettingsIcon,
//  StarIcon,
//} from '@chakra-ui/icons';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="blue" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src={'../../assets/images/profile/Dan-Profile.png'}
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
<>
      <Container maxW={'7xl'} p="12">
        <Heading as="h1"
        textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
        fontSize={'4xl'}
        fontWeight={'bold'}
        color={useColorModeValue('gray.900', 'white')}
        >
          Blogs &amp; Social Media
      </Heading>
    
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={
                  'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                }
                alt="Blog and Social Media Syndication Image"
                objectFit="contain"
              />
            </Link>
          </Box>
        </Box>
      </Box>

        <Box
          display="flex"
          flex="1"
          
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '6', sm: '4' }}>
          <BlogTags tags={['Blog Posts', 'Media Syndication', 'SEO', 'Topic Expert']} />
          <Heading marginTop="4">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              We create and Syndicate Blogs
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            marginBottom="5"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
          </Text>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            Albinson can help your organization by creating a blog that syndicates to your Social Media Channels.
            This helps your organization's website make serious gains in SEO rankings.
            By posting industry articles, your establishment can emerge as an industry and subject expert.
          </Text>  
          <Text
            as="p"
            marginTop="4"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            We carefully devise blog strategies, topic lists, image assets, and even create post schedules.
            Organizations that invest in a Blog and Social Media Syndication Strategy benefit greatly. 
            We can setup or refresh your Social Channels as well.
          </Text>
          <Text
            as="p"
            marginTop="4"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            Contact us today to discuss your organizational goals!
            <br />
            </Text>
          <BlogAuthor name="Dan Albinson" date={new Date('2023-01-01T19:01:27Z')} />
        </Box>
    </Container>
</>
  );
};

export default ArticleList;