import React from 'react';
import CaptionCarouselMK from './components/Marketing/CaptionCarouselMK';
import Header from './sections/Header';
import Footer from './sections/Footer';


function Marketing() {
    return (
        <>
        <Header />
        <CaptionCarouselMK />
            <div>
                <h1>THIS is the Marketing page</h1>
            </div>
        <Footer />
        </>
    );
}

export default Marketing;