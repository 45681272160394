import {
    Container,
    SimpleGrid,
    Box,
    //Image,
    Flex,
    ListItem,
    ListIcon,
    //Link,
    //Button,
    Heading,
    Text,
    Stack,
    //StackDivider,
    UnorderedList,
    //Icon,
    //useColorModeValue,
  } from '@chakra-ui/react';
  //import {
   // IoAnalyticsSharp,
   // IoLogoBitcoin,
    //IoSearchSharp,
   // IoEarthSharp,
   // IoCameraSharp,
   // IoImagesOutline,
   // IoBrowsersOutline,
 // } from 'react-icons/io5';
 // import {
 //   MdDevicesOther,
 // } from 'react-icons/md';
  import {
    CheckCircleIcon,
  } from '@chakra-ui/icons';

  import { ReactElement } from 'react';
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function SplitWithImage() {
    return (
      //<Container maxW={'full'} py={12}>
      <Container maxW={'5xl'} py={12}>
        <Box marginBottom={'25'}>
        <SimpleGrid columns={{ base: 2, md: 2, lg: 1 }} spacing={10}>

          <Stack spacing={4} w={'full'}>
          {/*<Stack spacing={6} w={'full'} maxW={'lg'}>*/}
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  //height: useBreakpointValue({ base: '20%', md: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.300',
                  zIndex: -1,
                }}>
                eCommerce
              </Text>
              {' '}
              <Text color={'blue.400'} as={'span'}>
              &amp; Web Projects
              </Text>{' '}
            </Heading>
          {/*</Stack>*/}
            <Text fontSize={{ base: 'lg', lg: 'xl' }} color={'gray.500'} display={'inline-block'}>
               A few sites that Albinson has created:
            </Text>



            {/*<SimpleGrid columns={2} spacingX='10px' spacingY='20px'>*/}

              <UnorderedList spacing={3} fontSize={{ base: 'lg', md: 'xl', lg: '3xl' }}  color={'gray.500'} listStyleType={'none'}> 
                <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                  <a href="https://aquaticsupportsystems.com/" rel="noreferrer" title="Shopify Platform eCommerce Site" target="_blank">Aquatic Support Systems - eCommerce (Shopify)</a>
                </ListItem>
                <Box>
                  <a href="https://aquaticsupportsystems.com/" rel="noreferrer" title="Shopify Platform eCommerce Site" target="_blank">
                    <img src={'/assets/images/technology/AquaticSupportSystems-Ecommerce1200.png'} alt={'Aquatic Support Systems - eCommerce'} />
                  </a>
                </Box>
                <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                  <a href="https://wyndhamhillsmn.com/" rel="noreferrer" title="Residential Real Estate Development Site" target="_blank">Wyndham Hills Development - Residential Lots</a>
                  <br />
                  <a href="https://wyndhamhillsmn.com/" rel="noreferrer" title="Residential Real Estate Development Site" target="_blank">
                    <img src={'/assets/images/technology/WyndhamHillsDevelopmentWebsite.png'} alt={'Wyndham Hills Development Site'} />
                  </a>
                </ListItem>
                <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                    <a href="https://paratuswealth.com/"  rel="noreferrer" title="Investment Advisor Site" target="_blank">Paratus Wealth Management - Advisor</a>
                    <br />
                    <a href="https://paratuswealth.com/" rel="noreferrer" title="Investment Advisor Site" target="_blank">
                    <img src={'/assets/images/technology/ParatusWealth.png'} alt={'Financial Advisor Website'}  />
                  </a>
                </ListItem>   
                <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                    <a href="https://stricklandinspections.com/"  rel="noreferrer" title="Stickland Home Inspections - Site Updates &amp; Maintenance" target="_blank">Strickland Home Inspections</a>
                    <br />
                    <a href="https://stricklandinspections.com/" rel="noreferrer" title="Stickland Home Inspections - Site Updates &amp; Maintenance" target="_blank">
                    <img src={'/assets/images/technology/StricklandInspectionsWebsite.png'} alt={'Strickland Inspections - WordPress Site Upkeep'} />
                  </a>
                </ListItem>           
                <ListItem textDecoration={'none'} paddingBottom={'50px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                  <a href="https://legacyslimesale.com/"  rel="noreferrer" title="Donated LCA 5th Grade Small Biz Experience eCommerce Site" target="_blank">5th Grade Business Experience - eCommerce (Woo Commerce)</a>
                  <br />
                    <a href="https://legacyslimesale.com/" rel="noreferrer" title="5th Grade Small Business Experience - Slime Sale" target="_blank">
                    <img src={'/assets/images/technology/5thGradeBusinessExperienceWeb.png'} alt={'WooCommerce - eCommerce'} />
                  </a>
                </ListItem>
             {/*} </UnorderedList>
              <UnorderedList spacing={3} fontSize={{ base: 'md', lg: 'lg' }}  color={'gray.500'} listStyleType={'none'}> */}
            <Heading fontSize={{ base: '2xl', md: '3xl', lg: '3xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  //height: useBreakpointValue({ base: '20%', md: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.300',
                  zIndex: -1,
                }}>
                Additional Sites
              </Text>
              {' '}
              <Text color={'blue.400'} as={'span'}>
                Dan Albinson has worked on...
              </Text>{' '}
            </Heading>
          {/*</Stack>*/}
           {/*} <Text fontSize={{ base: 'xl', lg: 'xl' }} color={'gray.500'} display={'inline-block'}>
               A few sites that Albinson has created:
              </Text>   */}         
              <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                  <a href="https://incentiveservices.com/"  rel="noreferrer" title="Incentive Services - Non-Cash Incentives and Recognition Platform" target="_blank">Incentive Services - Corporate WordPress Site</a>
                  <br />
                    <a href="https://legacyslimesale.com/" rel="noreferrer" title="Incentive Services - Non-Cash Incentives and Recognition Platform" target="_blank">
                    <img src={'/assets/images/technology/IncentiveServicesWebsite.png'} alt={'Corporate WordPress Site'} />
                  </a>
                </ListItem>
                <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                  <a href="https://istravelincentives.com/"  rel="noreferrer" title="Travel Registration Site -  Membership Site" target="_blank">Travel - WordPress Multi-Site | Membership Site</a>
                  <br />
                    <a href="https://istravelincentives.com/" rel="noreferrer" title="Travel Registration Site -  Membership Site" target="_blank">
                    <img src={'/assets/images/technology/IS_TRAVEL_INCENTIVES_Web.png'} alt={'Membership Site'} />
                  </a>
                </ListItem>
                <ListItem textDecoration={'none'} paddingBottom={'30px'}>
                  <ListIcon as={CheckCircleIcon} color='blue.500'></ListIcon>
                  <a href="https://wholesaleincentivegiftcards.com/"  rel="noreferrer" title="Wholesale Incentive Gift Cards" target="_blank">Wholesale Incentive Gift Card</a>
                  <br />
                    <a href="https://wholesaleincentivegiftcards.com/" rel="noreferrer" title="Wholesale Incentive Gift Cards" target="_blank">
                    <img src={'/assets/images/technology/WholesaleIncentiveGiftCardsWeb.png'} alt={'WordPress Site'} />
                  </a>
                </ListItem>
              </UnorderedList>
              {/*</SimpleGrid>*/}           
          </Stack>

        </SimpleGrid>
       </Box>




        {/*  <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                //'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                '/assets/images/realestate/LotSalesIllustration.jpg'
                }
              objectFit={'cover'}
            />
              </Flex>*/}

       </Container> 
       //</SimpleGrid>     



      //</Container>
    );
  }