import React from 'react';
import CityHopkinsStats from './components/RealEstate/CityHopkinsStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityHopkins() {
  return (
    <>
      <Header />
      <CityHopkinsStats />
      <Footer />
    </>
    );
  }
export default CityHopkins;