import React from 'react';
import CityOakGroveStats from './components/RealEstate/CityOakGroveStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityOakGrove() {
  return (
    <>
      <Header />
      <CityOakGroveStats />
      <Footer />
    </>
    );
  }
export default CityOakGrove;