import React from 'react';
import CityChaskaStats from './components/RealEstate/CityChanhassenStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityChanhassen() {
  return (
    <>
      <Header />
      <CityChaskaStats />
      <Footer />
    </>
    );
  }
export default CityChanhassen;