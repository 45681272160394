import {
    Button,
    Flex,
    Heading,
    Image,
    ListItem,
    ListIcon,
    Stack,
    Link,
    Text,
    UnorderedList,
    useBreakpointValue,
  } from '@chakra-ui/react';
  import {
    CheckCircleIcon,
  } from '@chakra-ui/icons';

  export default function SplitScreen() {
    return (
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex flex={1}>
          <Image
            alt={'Login Image'}
            objectFit={'cover'}
            src={
              //'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
              './assets/images/technology/TecheCommerceFiberOptic.jpg'
            }
          />
        </Flex>

        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={6} w={'full'} maxW={'lg'}>

            <Heading fontSize={{ base: '3xl', md: '2xl', lg: '3xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', md: '20%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.300',
                  zIndex: -1,
                }}>
                Recently 
                </Text>
              <br />{' '}
              <Text color={'blue.400'} fontSize={{base: '2xl', md:'2xl', lg:'3xl'}} as={'span'}>
                 Delivered Services:
              </Text>
            </Heading>
            <UnorderedList marginLeft={8} fontSize={{ base: 'md', lg: 'lg' }} color={'gray.100', 'gray.500'}>
                  <ListItem>Shopify eCommerce Site</ListItem>                
                  <ListItem>Site Theme Setup &amp; Development</ListItem>
                  <ListItem>Product Tables Revisions</ListItem>
                  <ListItem>Product Catalog Imagery</ListItem>
                  <ListItem>Inventory &amp; Reporting</ListItem>
                  <ListItem>Shipping &amp; Packing Slips</ListItem>
                  <ListItem>Invoicing</ListItem>
                  <ListItem>Store Promotion | Blogs &amp; Social Media</ListItem>
                  <ListItem>Product Photography | Slides </ListItem>
                  <ListItem>Product | Store Video</ListItem>
                  <ListItem>Discount Code Promotions</ListItem>
                  <ListItem>Ongoing Web Support &amp; Updates</ListItem>
                  <ListItem>Email Marketing Campaigns</ListItem>
                  <ListItem>Custom QR Codes</ListItem>
                  <ListItem>Corporate Branding Services</ListItem>
                  <ListItem>Business Cards &amp; Signage</ListItem>
                  <ListItem>and more...</ListItem>
                </UnorderedList>                
              <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                How can we serve you?
              </Text>

            <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Link href={'./ContactUs'}>
              <Button
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
               // href={'././ContactUs'}
                _hover={{
                  bg: 'blue.500',
                }}>
                Contact Us
              </Button>
            </Link>
            <Link href='technology'>
              <Button 
              rounded={'full'
              }>
                Technology Home
              </Button>
            </Link>
            </Stack>
          </Stack>
        </Flex>

      </Stack>
    );
  }


