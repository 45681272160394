import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import RealEstateListingComp from './components/RealEstate/RealEstateListingComp';


function RealEstateListing() {
    return(
        <>
            <Header />
            <RealEstateListingComp />
            <Footer />
        </>
    );
}
export default RealEstateListing;