import React from 'react';
import CityEdenPrairieStats from './components/RealEstate/CityEdenPrairieStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityEdenPrairie() {
  return (
    <>
      <Header />
      <CityEdenPrairieStats />
      <Footer />
    </>
    );
  }
export default CityEdenPrairie;