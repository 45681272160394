import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import MarketingEmailComp from './components/Marketing/MarketingEmailComp';

function MarketingEmail() {
    return (
        <>
        <Header />
        <MarketingEmailComp />
        <Footer />
        </>
    );
}

export default MarketingEmail;