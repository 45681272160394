import React from "react";
import { 
  ChakraProvider, 
  extendTheme, 
 // Stack, 
 // Image, 
  Container, 
 // Show, 
 // Flex 
} from "@chakra-ui/react";
//Flex, Divider, Text,


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import { Homepage } from './';
import Home from './Home';
import RealEstate from './RealEstate';
import Technology from './Technology';
import Marketing from './Marketing';
import ContactUs from './ContactUs';
import PageNotFound from './PageNotFound';
import CityAndover from './CityAndover';
import CityBlaine from './CityBlaine';
import CityChanhassen from './CityChanhassen';
import CityChaska from './CityChaska';
import CityEdenPrairie from './CityEdenPrairie';
import CityGoldenValley from './CityGoldenValley';
import CityHamLake from './CityHamLake';
import CityHopkins from './CityHopkins';
import CityMapleGrove from './CityMapleGrove';
import CityMinnetonka from './CityMinnetonka';
import CityOakGrove from './CityOakGrove';
import CityPlymouth from './CityPlymouth';
import CityPriorLake from './CityPriorLake';
import CityRamsey from './CityRamsey';
import CitySavage from './CitySavage';
import CitySpringLakePark from './CitySpringLakePark';
import Blog from './Blog';
import TechWebDevelopment from './TechWebDevelopment';
import TechEcommerce from './TechEcommerce';
import TechSEO from './TechSEO';
import TechResponsive from './TechResponsive';
import TechWebsites from './TechWebsites';

import RealEstateListing from './RealEstateListing';
import RealEstateBuying from './RealEstateBuying';
import RealEstateCities from './RealEstateCities';
import RealEstateAboutUs from './RealEstateAboutUs';
import MarketingBlog from './MarketingBlog';
import MarketingEmail from './MarketingEmail';
import MarketingPhoto from './MarketingPhoto';
import SlimeSale from "./SlimeSale";

//import Albinson from "/";
//import "/public/css/local-styles.css";

//./css/local-styles.css
const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

  const theme = extendTheme({ colors })

  function App() {
    return (

<>


      <ChakraProvider theme={theme}>
       <Router>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='RealEstate' element={<RealEstate />} />
            <Route exact path='Technology' element={<Technology />} />
            <Route exact path='Marketing' element={<Marketing />} />
            <Route exact path='ContactUs' element={<ContactUs />}  />
            <Route exact path='CityAndover' element={<CityAndover />} />
            <Route exact path='CityBlaine' element={<CityBlaine />} />
            <Route exact path='CityChaska' element={<CityChaska />} />
            <Route exact path='CityEdenPrairie' element={<CityEdenPrairie />} />
            <Route exact path='CityGoldenValley' element={<CityGoldenValley />} />
            <Route exact path='CityHamLake' element={<CityHamLake />} />
            <Route exact path='CityHopkins' element={<CityHopkins />} />
            <Route exact path='CityMapleGrove' element={<CityMapleGrove />} />
            <Route exact path='CityMinnetonka' element={<CityMinnetonka />} />
            <Route exact path='CityOakGrove' element={<CityOakGrove />} />
            <Route exact path='CityPlymouth' element={<CityPlymouth />} />
            <Route exact path='CityPriorLake' element={<CityPriorLake />} />
            <Route exact path='CityRamsey' element={<CityRamsey />} />
            <Route exact path='CitySavage' element={<CitySavage />} />
            <Route exact path='CitySpringLakePark' element={<CitySpringLakePark />} />
            <Route exact path='Blog' element={<Blog />}  />
            <Route exact path='TechWebDevelopment' element={<TechWebDevelopment />} />
            <Route exact path='TechEcommerce' element={<TechEcommerce />} />
            <Route exact path='TechSEO' element={<TechSEO />} />
            <Route exact path='TechResponsive' element={<TechResponsive />} />
            <Route exact path='TechWebsites' element={<TechWebsites />} />
            <Route exact path='RealEstateListing' element={<RealEstateListing />} />
            <Route exact path='RealEstateBuying' element={<RealEstateBuying />} />
            <Route exact path='RealEstateCities' element={<RealEstateCities />} />
            <Route exact path='CityChanhassen' element={<CityChanhassen />} />
            <Route exact path='RealEstateAboutUs' element={<RealEstateAboutUs />}  />
            <Route exact path='MarketingBlog' element={<MarketingBlog />} />
            <Route exact path='MarketingEmail' element={<MarketingEmail />} />
            <Route exact path='MarketingPhoto' element={<MarketingPhoto />} />
            <Route exact path="SlimeSale" element={<SlimeSale />} />
            <Route path='PageNotFound' element={<PageNotFound />}  />
          </Routes>
        </Router>
          <Container  maxW="100%">



          </Container>


         {/*} </Switch>
      </Router>*/}


        </ChakraProvider>



    </>
  );
}
export default App;