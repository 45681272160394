import React from 'react';
//import CaptionCarouselTK from './components/Technology/CaptionCarouselTK';
import TechWebDevComp from './components/Technology/TechWebDevComp';
import Header from './sections/Header';
import Footer from './sections/Footer';
//import ContactUsComponent from'./components/ContactUsComponent';

function TechWebDevelopment() {
    return (
        <>
        <Header />
        {/*<CaptionCarouselTK />*/}
        <TechWebDevComp />
        <Footer />
        </>
    );
}
export default TechWebDevelopment;