import React from 'react';
//import CaptionCarouselTK from './components/Technology/CaptionCarouselTK';
import TechSEOComp from './components/Technology/TechSEOComp';
import TechWBckGroundImageComp from './components/Technology/TechWBckGroundImageComp';
import Header from './sections/Header';
import Footer from './sections/Footer';
//import ContactUsComponent from'./components/ContactUsComponent';

function TechSEO() {
    return (
        <>
        <Header />
        {/*<CaptionCarouselTK />*/}
        <TechSEOComp />
        <TechWBckGroundImageComp />
        <Footer />
        </>
    );
}
export default TechSEO;