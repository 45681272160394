import {
    Container,
    SimpleGrid,
    Box,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
   // IoAnalyticsSharp,
   // IoLogoBitcoin,
    //IoSearchSharp,
   // IoEarthSharp,
   // IoCameraSharp,
    IoImagesOutline,
    IoBrowsersOutline,
  } from 'react-icons/io5';
  import {
    MdDevicesOther,

  } from 'react-icons/md';

  import { ReactElement } from 'react';
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function SplitWithImage() {
    return (
      <Container maxW={'5xl'} py={12}>
        <Box marginBottom={'25'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* IMAGE LEFT / 1ST IN RESPONSIVE... */}
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                '/assets/images/realestate/RealEstateMNWWIMarketPlace.jpg'
              }
              objectFit={'cover'}
            />
          </Flex>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'green.950'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('green.200', 'green.400')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Finding Your Next Home
            </Text>
            <Heading>Strategic Market Search </Heading>
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                There is no denying the challenges the lack of home inventory presents for those on a Home Search.
                As a Buyer's Agent, I prepare my clients to brace themselves for a very competitive environment.
             </Text>
             <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                Low inventory gives the Seller’s the upper hand.
                Buyers should expect to be competing with multiple offers on most listings.
            </Text>
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                After reviewing market conditions, our conversation rapidly turns what features, 
                locations and criteria are most important to our clients.
            </Text>
            <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                Following our discussion, we create a custom MLS home search which delivers matches to your email.
                Once alerted of Coming Soon and Newly Active Listings,  as soon as they hit the market.

              </Text>


              <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >
                Showings are scheduled ASAP in accordace with client availability. 
                The market requires quick decisions.
                These days, offers may need additional items such as Escalation Clauses (to incrementally outbid the next highest offer), 
                and Mortgage Gap coverage for appraisal shortfalls.
             </Text> 
             <Text 
                fontSize={'lg'}
                color={useColorModeValue('gray.800', 'gray.200')}
                >  
                We work with our clients to assemble and present their best possible offer.
                We eagerly seek to help you on your home search!
                Call today at (612) 270-0492!
            </Text>  
 
           
          </Stack>

        </SimpleGrid>
       </Box>
<br />
             <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* IMAGE LEFT / 1ST IN RESPONSIVE... */}

          <Stack spacing={3}>

          <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.500')}
                />
              }>

            </Stack>

            <Text
              textTransform={'uppercase'}
              color={'green.950'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('green.200', 'green.400')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}
              marginBottom={'5'}>
              Buying Services
            </Text>
         {/*} </Stack>
          <Stack spacing={2}>
          
          BUYING SERVICES
Free Listings Reports
CMA's
Showings
Purchase Documentation
Purchase Negotiations
Inspection Orders
Financing Referrals
Final Walk
Closing Coordination
          
          */}
          <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}
                  paddingTop={'0'}>
                  HOME SEARCH CRITERIA<br />
                <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    Complete our Search Form specifying search criteria.
                </Text>
            </Text>
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Financing Pre-Approval<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    Get a Pre-Approval Letter from your preferred lender. 
                 </Text>
            </Text> 
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Active Listing Reports<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                    Listings emailed as soon as they hit the market.
                </Text>
            </Text>   
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                  >
                  Schedule Showings<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                  Coordinating and scheduling showings ASAP.
                </Text>
            </Text>   
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Offer Documentation<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                  Guiding you though complex offers. 
                </Text>
            </Text>
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Purchase Negotiations<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                  Experience helps in the negotiation process. 
                  </Text>
            </Text>    

            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Closing Coordination &amp; Title Company<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                   Completing transaction docs &amp; coordinating details.
                 </Text>
            </Text>   
            <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('blue.600', 'blue.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  mb={'0'}>
                  Final Walk<br />
                  <Text 
                    as={'span'}
                    fontSize={'md'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    fontWeight={'400'}
                    textTransform={'none'}
                    >    
                  Reviewing the property just before closing to verify its condition.
                 </Text>
            </Text>  
            <Text fontSize='1.2em' marginTop='10' py='2' fontWeight='semibold'>
                Put Us To Work For You
            </Text>
              <Feature
                MdDevicesOther
                icon={
                  <Icon as={MdDevicesOther} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.200', 'green.100')}
                text={'Professional, Systematic & Detailed Approach'}
              />
              <Feature
                IoImagesOutline
                icon={<Icon as={IoImagesOutline} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.200', 'green.100')}
                text={'Micro-Market Analysis & Research of Neighborhood'}
              />
              <Feature
                IoBrowsersOutline
                icon={
                  <Icon as={IoBrowsersOutline} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.200', 'green.100')}
                text={'Professional Marketing & Communication'}
              />
            </Stack>
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                //'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                '/assets/images/realestate/LotSalesIllustration.jpg'
                }
              objectFit={'cover'}
            />
          </Flex>

        </SimpleGrid>     


      </Container>
    );
  }