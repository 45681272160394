import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import MarketingBlogComp from './components/Marketing/MarketingBlogComp';

function MarketingBlog() {
    return (
        <>
        <Header />
        <MarketingBlogComp />
        <Footer />
        </>
    );
}

export default MarketingBlog;