import React from 'react';
//import CaptionCarouselTK from './components/Technology/CaptionCarouselTK';
import TechWebsiteComp from './components/Technology/TechWebsiteComp';
import Header from './sections/Header';
import Footer from './sections/Footer';

function TechWebsites() {
    return (
        <>
        <Header />
        {/*<CaptionCarouselTK />*/}
        <TechWebsiteComp />
        <div>
           {/*} <h1>THIS is the TECH Websites page</h1>*/}
        </div>
        <Footer />
        </>
    );
}
export default TechWebsites;
