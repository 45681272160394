import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import RealEstateBuyingComp from './components/RealEstate/RealEstateBuyingComp';

function RealEstateBuying() {
    return(
        <>
            <Header />
            <RealEstateBuyingComp />
            <Footer />
        </>

    );
}
export default RealEstateBuying;