import React from 'react';
import CityBlaineStats from './components/RealEstate/CityBlaineStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityBlaine() {
  return (
    <>
      <Header />
      <CityBlaineStats />
      <Footer />
    </>
    );
  }
export default CityBlaine;