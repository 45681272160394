import React from 'react';
import CityPriorLakeStats from './components/RealEstate/CityPriorLakeStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityPriorLake() {
    return(
        <>
            <Header />
            <CityPriorLakeStats />
            <Footer />
        </>    
        );
    }
export default CityPriorLake;