import React from 'react';
import CityChaskaStats from './components/RealEstate/CityChaskaStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityChaska() {
  return (
    <>
      <Header />
      <CityChaskaStats />
      <Footer />
    </>
    );
  }
export default CityChaska;