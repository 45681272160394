import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import TechResponsiveComp from './components/Technology/TechResponsiveComp';

function TechResponsive() {
    return (
        <>
            <Header />
            <TechResponsiveComp />
            <Footer />
        </>
    );
}
export default TechResponsive;