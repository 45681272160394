import React from 'react';
import { 
  ChakraProvider, 
  //Stack, 
  //Image, 
  theme, 
  Container, 
  //Show, 
  //Flex 
} from "@chakra-ui/react";
//Flex, Divider, Text,
//import CTAwVideo from '../components/CTAwVideo';
import Header from './sections/Header';
import Footer from './sections/Footer';
import TechEcommerceComp from './components/Technology/TechEcommerceComp';
import TwoColumnImageTech from './components/Technology/TwoColumnImageTech';
//import Headline1 from "./components/Headline1";
//import CardProfile from "./components/CardProfile";



function TechEcommerce() {
    return (
  <>
    {/*  maxW="1200" */}
      <ChakraProvider theme={theme}>
          <Container maxW="100%">
            <Header />
             <TwoColumnImageTech />
             <TechEcommerceComp />
            <Footer />
          </Container>
        </ChakraProvider>
    </>
  );
}
export default TechEcommerce;