import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import RealEstateAboutUsComp from './components/RealEstate/RealEstateAboutUsComp';

function RealEstateAboutUs() {
    return(
        <>
            <Header />
            <RealEstateAboutUsComp />
            <Footer />
        </>

    );
}
export default RealEstateAboutUs;