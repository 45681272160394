import React from 'react';
import CityAndoverStats from './components/RealEstate/CityAndoverStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityAndover() {
  return (
    <>
      <Header />
      <CityAndoverStats />
      <Footer />
    </>
    );
  }
export default CityAndover;