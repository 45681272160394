import React from 'react';
import CaptionCarouselRE from './components/RealEstate/CaptionCarouselRE';
import Header from './sections/Header';
import Footer from './sections/Footer';

function RealEstate() {
    return (
        <>
        <Header />
        <CaptionCarouselRE />
        <div>
            <h1>THIS is the Real Estate page</h1>
        </div>
        <Footer />
        </>
    );
}

export default RealEstate;
