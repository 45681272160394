import React from 'react';
import CityPlymouthStats from './components/RealEstate/CityPlymouthStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityPlymouth() {
  return (
    <>
      <Header />
      <CityPlymouthStats />
      <Footer />
    </>
    );
  }
export default CityPlymouth;