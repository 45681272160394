    import {
      Box,
      //chakra,
      Container,
      Stack,
      Text,
      Image,
      Flex,
      VStack,
      Button,
      Heading,
      SimpleGrid,
      StackDivider,
      useColorModeValue,
      //VisuallyHidden,
      List,
      ListItem,
      Link,
    } from '@chakra-ui/react';
    //import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
    

    import { MdEmail } from 'react-icons/md';
    
    export default function TechWebDevComp() {
      return (
        <Container maxW={'7xl'}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}>
            <Flex>
              <Image
                rounded={'md'}
                alt={'product image'}
                src={
                  //'https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080'
                  '/assets/images/technology/iMac27SilverWebApps.png'
                }
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={{ base: '100%', sm: '400px', lg: '500px' }}
              />
            </Flex>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={'header'}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                  Web Development
                </Heading>
                <Text
                  color={useColorModeValue('gray.900', 'gray.400')}
                  fontWeight={300}
                  fontSize={'2xl'}>
                  HTML, CSS, Bootstrap, React, AWS, WordPress, eCommerce, Shopify, JavaScript, jQuery, etc...
                </Text>
              </Box>
    
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                  />
                }>
                <VStack spacing={{ base: 4, sm: 6 }}>
                  <Text color={useColorModeValue('gray.500', 'gray.400')}
                    fontSize={'2xl'}
                    fontWeight={'300'}
                    >Albinson has over 25+ years of Website Development experience!
                     Put our experience to work for youl
                  </Text>
                  <Text fontSize={'lg'}>
                    Albinson has worked on a vast range of business and personal websites, from small business to Fortune 500 Company App portals.
                    The websites vary from HTML, .Net, Angular, WordPress, Shopify, and React based sites. </Text>
                  <Text fontSize={'lg'}>
                    We develop responsive sites that utilize CSS, Bootrap, and Chakra-UI frameworks (to name a few), to make your site look good on any viewport.
                  </Text>
                </VStack>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={useColorModeValue('yellow.500', 'yellow.300')}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}>
                    A SAMPLE OF DEVELOPMENT PROJECTS
                  </Text>
    
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <List spacing={2}>
                      <ListItem>Responsive 1000+ Company Web App</ListItem>
                      <ListItem>Corporate WordPress Sites</ListItem>{' '}
                      <ListItem>Small Business Shopify eCommerce</ListItem>{' '}
                      <ListItem>WordPress WooCommerce Sites</ListItem>{' '}
                      <ListItem>eLearning Training Modules</ListItem>{' '}

                    </List>
                    <List spacing={2}>
                      <ListItem>Custom Memory Book UX/UI</ListItem>
                      <ListItem>Corporate Social Walls</ListItem>
                      <ListItem>Corporate Blog Sites</ListItem>
                      <ListItem>Real Estate Development Sites</ListItem>
                      <ListItem>Wireframes/Rapid Prototyping</ListItem>
                    </List>
                  </SimpleGrid>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={useColorModeValue('yellow.500', 'yellow.300')}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}>
                    Additional Areas of Expertise
                  </Text>
    
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <List spacing={2}>
                      <ListItem>
                        {/*<Text as={'span'} fontWeight={'semibold'}>*/}
                        Responsive & Mobile Design
                        {/*</Text>{' '}*/}
                      </ListItem>
                      <ListItem>
                          CRM's
                      </ListItem>
                      <ListItem>
                        Front-End Design
                        </ListItem>
                      <ListItem>
                        Browser Testing
                      </ListItem>
                    </List>
                    <List spacing={2}>
                      <ListItem>
                        Website & Email Hosting
                        </ListItem>
                      <ListItem>
                        Carousels | Galleries | Light Boxes
                      </ListItem>
                      <ListItem>
                        Retail Product Catalogs
                        </ListItem>
                      <ListItem>
                        And more...
                      </ListItem>
                    </List>
                  </SimpleGrid>
                </Box>
              </Stack>
              <Link 
                 href={'./ContactUs'}
              >
                <Button
                  rounded={'5'}
                  w={'full'}
                  mt={8}
                  size={'lg'}
                  py={'7'}
                  href={'././ContactUs'}
                  bg={useColorModeValue('blue.500', 'gray.50')}
                  color={useColorModeValue('white', 'green.900')}
                  textTransform={'uppercase'}
                  _hover={{
                    transform: 'translateY(2px)',
                    boxShadow: 'lg',
                  }}>
                  Go to Contact Us Page
                </Button>
              </Link>
              <Stack direction="row" alignItems="center" justifyContent={'center'}>
                <MdEmail />
                <Text>We look forward to speaking with you!</Text>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      );
    }