import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    Flex,
    Tag,
    useColorModeValue,
    Image,
    VStack,
    //Button,
  } from '@chakra-ui/react';

  
    const Logo = (props) =>  {
      return (
  <>
  <Container>
    <VStack>
      <Image
        rounded={'md'}
        alt={'Fish MLS Realty'}
        src={
          '/assets/images/logo/FishMLSLogo.svg'
        }
        fit={'cover'}
        align={'center'}
        maxW={'300px'}
        h={'relative'}
        border={"solid 2px #000"}
      />
    </VStack>
  </Container>
  <Container>
    <VStack>
      <Image
          rounded={'md'}
          alt={'Albinson, LLC - Real Estate'}
          src={
            '/assets/images/logo/Albinson_Logo_svg.svg'
          }
          fit={'cover'}
          align={'center'}
          maxW={'300px'}
          h={'relative'}
        />
      </VStack>
  </Container>
  </>

      );
    };
    const ListHeader = ({ children }) => {
      return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
          {children}
        </Text>
      );
    };
    
    export default function LargeWithLogoCentered() {
      return (
        <Box>
          <Container as={Stack} maxW={'6xl'} py={10}>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
              <Stack align={'flex-start'}>
                <ListHeader>Real Estate</ListHeader>
                <Link href={'RealEstateAboutUs'}>Real Estate Home</Link>
                <Link href={'RealEstateListing'}>Listing Services</Link>
                <Link href={'RealEstateBuying'}>Buying Services</Link>
                <Link href={'RealEstateAboutUs'}>About Us</Link>
                <Stack direction={'row'} align={'center'} spacing={2}>
                  <Link href={'RealEstateCities'}>Market Reports</Link>
                  <Tag
                    size={'sm'}
                    bg={useColorModeValue('green.300', 'green.800')}
                    ml={2}
                    color={'white'}>
                    New
                  </Tag>
                </Stack>
              </Stack>
              <Stack align={'flex-start'}>
                <ListHeader>Technology</ListHeader>
                <Link href={'TechWebsites'}>Websites</Link>
                <Link href={'TechEcommerce'}>eCommerce</Link>
                <Stack direction={'row'} align={'center'} spacing={2}>
                  <Link href={'#'}>WordPress</Link>
                  <Tag
                    size={'sm'}
                    bg={useColorModeValue('orange.300', 'orange.800')}
                    ml={2}
                    color={'white'}>
                    Coming Soon
                  </Tag>
                </Stack>
                <Stack direction={'row'} align={'center'} spacing={2}>
                  <Link href={'#'}>Shopify</Link>
                  <Tag
                    size={'sm'}
                    bg={useColorModeValue('orange.300', 'orange.800')}
                    ml={2}
                    color={'white'}>
                    Coming Soon
                  </Tag>
                </Stack>
                <Stack direction={'row'} align={'center'} spacing={2}>
                  <Link href={'Technology'}>React.js</Link>
                  <Tag
                    size={'sm'}
                    bg={useColorModeValue('blue.300', 'blue.800')}
                    ml={2}
                    color={'white'}>
                    This Site
                  </Tag>
                </Stack>

                <Link href={'TechSEO'}>SEO</Link>
                <Link href={'TechWebDevelopment'}>Web Development</Link>
              </Stack>
              <Stack align={'flex-start'}>
                <ListHeader>Marketing</ListHeader>
                <Link href={'MarketingEmail'}>Email Campaigns</Link>
                <Link href={'MarketingBlog'}>Blog Posts</Link>
                <Link href={'Marketing'}>Social Media</Link>
                <Link href={'Marketing'}>Design</Link>
                <Link href={'Marketing'}>Branding</Link>
                <Link href={'MarketingPhoto'}>Photography</Link>
              </Stack>
              <Stack align={'flex-start'}>
                <ListHeader>Company</ListHeader>
                <Link href={'#'}>Home</Link>
                <Link href={'RealEstate'}>Real Estate</Link>
                <Link href={'Technology'}>Technology</Link>
                <Link href={'Marketing'}>Marketing</Link>
                <Link href={'ContactUs'}>Contact Us</Link>
              </Stack>          
            </SimpleGrid>
          </Container>
          <Box py={10}>
           <Flex
              align={'center'}
              _before={{
                content: '""',
                borderBottom: '1px solid',
                flexGrow: 1,
                mr: 8,
              }}
              _after={{
                content: '""',
                borderBottom: '1px solid',
                flexGrow: 1,
                ml: 8,
              }}>
              <Logo />
            </Flex>
            <Text pt={6} fontSize={'sm'} textAlign={'center'}>
              © 2023 Albinson, LLC | All rights reserved {/*| <Button onClick={() => window.location.href = 'mailto:info@albinson.com'}>Contact Us</Button> */}
            </Text>
          </Box>
        </Box>
    );
  }