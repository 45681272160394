import {
  Container,
  SimpleGrid,
 // Box,
  Image,
  Flex,
  //Heading,
  Text,
  Stack,
 // HStack,
  //StackDivider,
  //Icon,
  useColorModeValue,
} from '@chakra-ui/react';


import { ReactElement } from 'react';

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={3}>
          <Text
            textTransform={'uppercase'}
            color={'blue.950'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.200', 'blue.400')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
            marginBottom={'5'}>
            Professional Images
          </Text>
          <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('blue.600', 'blue.300')}
                fontWeight={'700'}
                textTransform={'uppercase'}
                mb={'0'}>
                Producing Professional Imagery <br />
                <Text 
                  as={'span'}
                  fontSize={'md'}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  fontWeight={'400'}
                  textTransform={'none'}
                  >    
                  Capturing professional imagery requires technical expertise, 
                  light management, framing vision, and professional tools including hardware and software.
                  Albinson consistently produces captures "the shot".
                                </Text>
          </Text>   
          <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('blue.600', 'blue.300')}
                fontWeight={'700'}
                textTransform={'uppercase'}
                marginBottom={'0'}
                marginTop={'1'}
                >
                TOOLS<br />
                <Text 
                  as={'span'}
                  fontSize={'md'}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  fontWeight={'400'}
                  textTransform={'none'}
                  >
                <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('green.400', 'green.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                >
                  Mirrorless Camera
                </Text>
                  Albinson visits your property to review the home's features and conditions.
                  We send a punchlist report of items to address for maximum sale return.
              </Text>
          </Text>   
          <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('green.400', 'green.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                >
                 Drones and Action Cameras<br />
                <Text 
                  as={'span'}
                  fontSize={'md'}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  fontWeight={'400'}
                  textTransform={'none'}
                  >    
                 
                  Additionally, we use DJI drones and GoPro action cameras to get images from unique perspectives that are silky smooth. 
                  Aerial photography opens a whole new world of possibilities for action videos and landscape photos. 
                  The camera gimbals help capture smooth images, even while moving.
              </Text>
          </Text>
          <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('green.400', 'green.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                >
                Software<br />
                <Text 
                  as={'span'}
                  fontSize={'md'}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  fontWeight={'400'}
                  textTransform={'none'}
                  >   
                  Utilizing the full Adobe Creative Cloud suite of programs, 
                  we optimize our imagery following the photo shoot. 
                  We color correct, clean, and stylize the images until they deliver the desired look and feel. 
                </Text>
          </Text>    
          <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('green.400', 'green.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                >
                  Subjects<br />  
                <Text 
                  as={'span'}
                  fontSize={'md'}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  fontWeight={'400'}
                  textTransform={'none'}
                  > 
                  We shoot a variety of subjects including: Product Photography, 
                  Real Estate, Portraits and more.
               </Text>
          </Text> 

              <SimpleGrid 
                  columns={'2'}
                  paddingTop={'0'} 
                  marginTop={'1'}
                  marginLeft={'20'}
                  fontSize={'md'}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  fontWeight={'400'}
                  textTransform={'none'}
                   >
                <box 
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('green.400', 'green.300')}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  marginBottom={'0'}
                  marginTop={'1'}
                  width={'100%'}
                >
                Images used for:
                </box>
                <br />
                <li>
                   Websites
                </li>
                <li>
                   Blogs
                </li>
                <li>
                    Social Media
                </li>
                <li>
                    Email Campaigns
                </li>
                <li>
                    Newsletters
                </li>
                <li>
                    Sell Sheets
                </li> 
                <li>
                    Catalogs
                </li>
                <li>
                    eCommerce
                </li>
                <li>
                    Photo Galleries
                </li>
                <li>
                    Vinyl Banners
                </li>
                <li>
                    Videos
                </li>
                <li>
                    eTraining Modules
                </li>
                
            </SimpleGrid>                   
          </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              '/assets/images/marketing/ProImageToolsAlbinsonProResults.png'
            }
            objectFit={'cover'}
          />
        </Flex>

      </SimpleGrid>     


    </Container>
  );
}