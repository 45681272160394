import React from 'react';
import { 
  ChakraProvider, 
  //Stack, 
  //Image, 
  theme, 
  Container, 
  //Show, 
  //Flex 
} from "@chakra-ui/react";

import Header from './sections/Header';
import Footer from './sections/Footer';
import BlogPostComponent from './components/BlogPostComponent';


function Blog() {
    return (
  <>
    {/*  maxW="1200" */}
      <ChakraProvider theme={theme}>
          <Container maxW="100%">
            <Header />
            <BlogPostComponent />
            <Footer />
          </Container>
        </ChakraProvider>
    </>
  );
}
export default Blog;