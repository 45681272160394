
import React from 'react';
import {
 Container,
} from '@chakra-ui/react';

import { 
    AspectRatio,
    
  } from '@chakra-ui/layout';


export default function SlimeSaleComp () {
  return (<>
      <Container maxW="100%" maxH="100%" backgroundColor="#0B2140" paddingBottom="1000px" >
      <AspectRatio  ratio={16 / 9}  maxW='1220px' textAlign='center' marginLeft="auto" marginRight="auto" paddingTop="15%" >
        <iframe
            title='2024 Legacy Slime Sale'
            width="100%"
            height="100%"
            className='videoWrapperYT' 
            height='auto'
            src="https://www.youtube-nocookie.com/embed/_kGgor3ggRk?rel=0&modestbranding=1&showinfo=0" 
            frameBorder="0"  
            title="YouTube video player" 
            allowFullScreen='true'
            backgroundColor='#0B2140'
            >
            </iframe>
      </AspectRatio>
    </Container>
  </>
  );
}