import React from 'react';
import CityHamLakeStats from './components/RealEstate/CityHamLakeStats';
import Header from './sections/Header';
import Footer from './sections/Footer';

function CityHamLake() {
  return (
    <>
      <Header />
      <CityHamLakeStats />
      <Footer />
    </>
    );
  }
export default CityHamLake;