import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useColorMode,
    useBreakpointValue,
    useDisclosure,
  } from '@chakra-ui/react';
  import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  } from '@chakra-ui/icons';

 // import { Router, Route,  } from 'react-router-dom';
//  import PageNotFound from './pages/PageNotFound';

 // import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

//import RealEstate from "./pages/RealEstate";
//import Technology from "./pages/Technology";
  
  import { MoonIcon, SunIcon } from '@chakra-ui/icons';


  //import RealEstate from './pages/RealEstate';



  export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    return (
      

     

      <Box>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Link href="/">
            <Text
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'Daniel'}
              fontSize={'xx-large'}
              fontWeight={'bold'}
              color={useColorModeValue('gray.900', 'white')}
              >
              Albinson
            </Text>
            </Link>
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />

            </Flex>
          </Flex>
  
         <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
          <Flex>
            {/*<Button
              as={'a'}
              fontSize={'sm'}
              fontWeight={400}
              variant={'link'}
              href={'#'}>
              Sign In
            </Button>
            <Button
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              bg={'pink.400'}
              href={'#'}
              _hover={{
                bg: 'pink.300',
              }}>
              Sign Up
            </Button>
            <Switch />*/}

            <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </Button>


          </Flex>
          </Stack>
        </Flex>
  
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    );
  }
  
  const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  
    return (
      <Stack direction={'row'} spacing={4}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={2}
                  href={navItem.href ?? '#'}
                  fontSize={'sm'}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };
  
  const DesktopSubNav = ({ label, href, subLabel }: NavItem ) => {
    return (
      <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'pink.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
  };
  
  const MobileNav = () => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };
  
  const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={Link}
          href={href ?? '#'}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children.map((child) => (
                <Link key={child.label} py={2} href={child.href}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };
  
  interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
  }
  
  //const NAV_ITEMS: Array<NavItem> = [
    const NAV_ITEMS: Array = [
    {
      
      label: 'Real Estate',
      children: [
        {
          label: 'Listing Services',
          subLabel: 'Real Estate Listing Excellence',
          href:'./RealEstateListing',
        },
        {
          label: 'Buying Services',
          subLabel: 'Help You Find Your Home',
          href:'./RealEstateBuying',
        },
        {
          label: 'About Us',
          subLabel: 'About Albinson Real Estate',
          href: './RealEstateAboutUs',
        },
        {
          label: 'City Market Data',
          subLabel: 'City Market Activity',
          href: './RealEstateCities',
          children: [
            {
              label: 'City of Andover',
              subLabel: 'Andover City Stats',
              href: 'CityAndover',
            },
            {
              label: 'City of Blaine',
              subLabel: 'Blaine City Stats',
              href: 'CityBlaine',
            },
            {
              label: 'City of Chanhassen',
              subLabel: 'Chanhassen City Stats',
              href: 'CityChanhassen',
            },
            {
              label: 'City of Chaska',
              subLabel: 'Chaska City Stats',
              href: 'CityChaska',
            },
            {
              label:'City of Eden Prairie',
              subLabel: 'Eden Prairie Stats',
              href: 'CityEdenPrairie',
            },
            {
              label: 'City of Golden Valley',
              subLabel: 'Golden Valley Stats',
              href: 'CityGoldenValley'
            },
            {
              label: 'City of Ham Lake',
              subLable: 'Ham Lake Stats',
              href: 'CityHamLake',
            },
            {
              label: 'City of Hopkins',
              subLabel: 'Hopkins City Stats',
              href: 'CityHopkins',
            },
            {
              label: 'City of Maple Grove',
              subLabel: 'Maple Grove Stats',
              href: 'CityMapleGrove',
            },
            {
              label: 'City of Minnetonka',
              subLabel: 'Minnetonka City Stats',
              href: 'CityMinnetonka',
            },
            {
              label: 'City of Oak Grove',
              subLabel: 'Oak Grove Stats',
              href: 'CityOakGrove',
            },
            {
              label: 'City of Plymouth',
              subLable: 'Plymouth City Stats',
              href: 'CityPlymouth',
            },
            {
              label: 'City of Ramsey',
              subLabel: 'Ramsey City Stats',
              href: 'CityRamsey',
            },
            {
              label: 'City of Savage',
              subLable: 'Savage City Stats',
              href: 'CitySavage',
            },
          ]
        },
      ],
    },
    {
      label: 'Technology',
      children: [
        {
          label: 'Responsive Websites',
          subLabel: 'Designed for Mobile & Desktop',
          href:'TechResponsive',
        },
        {
          label: 'eCommerce',
          subLabel: 'WooCommerce & Shopify',
          href:'TechEcommerce',
        },
        {
          label: 'SEO',
          subLabel: 'Site Rank Optimization',
          href:'TechSEO',
        },
        {
          label: 'Web Development',
          subLabel: 'Website Development',
          href:'TechWebDevelopment',
        },
        {
          label: 'Websites',
          subLabel: 'Website Development',
          href: 'TechWebsites',
        }
      ],
    },
    {
      label: 'Marketing',
      children: [
        {
          label: 'HTML Email Campaigns',
          subLabel: 'Designed for Mobile & Desktop',
          href:'MarketingEmail',
        },
        {
          label: 'Blog & Social Media Syndication',
          subLabel: 'Creating Your Brand Identity',
          href:'MarketingBlog',
        },
        {
          label: 'Photography & Videography',
          subLabel: 'Professional Results',
          href:'MarketingPhoto',
        },
      ],
    },
    {
      label: 'Blog',
      href:'Blog',

    },
    {
      label: 'Contact Us',
      href:'ContactUs',
     // children: [
     //   {
     //     label: 'Contact Us',
     //     subLabel: 'Contact Albinson.com',
     //     href:'ContactUs',
     //   },
     // ],
    },
  
  ];