import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import NotFoundComponent from './components/NotFoundComponent';

function PageNotFound() {
    return (
        <>
        <Header />
        <NotFoundComponent />
        <Footer />
        </>

    );
}

export default PageNotFound;